<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <br>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeEmpty',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    }
  }
}
</script>
